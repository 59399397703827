<template>
  <div>
    <div v-if="authState === 'signedin'" class="main-box">
      <h2 class="text-center">{{ $t("account") }}</h2>
      <div class="main-text">
        <p class="membership-text">{{ $t("email_address") }}</p>
        {{ email }}
        <p class="membership-text">{{ $t("plan") }}</p>
        <div v-if="user_props === 'metered'">
          {{ $t("user_props_metered") }}
        </div>
        <div v-if="user_props === 'limited'">
          {{ $t("user_props_limited") }}
        </div>
        <div v-if="user_props === 'unlimited'">
          {{ $t("user_props_unlimited") }}
        </div>
        <div v-if="user_props === 'metered'">
          <p class="membership-text">{{ $t("charge_for_this_month") }}</p>
          <div v-if="usage">
            <p>
              {{ usage }} {{ $t("charge_text1") }} {{ usage / 5 }}
              {{ $t("charge_text2") }}
            </p>
          </div>
          <div v-else>
            <p>0{{ $t("charge_text1") }}0{{ $t("charge_text2") }}</p>
          </div>
        </div>
        <div v-if="user_props === 'metered' || user_props === 'limited'">
          <p class="membership-text">{{ $t("note") }}</p>
          <ul>
            <li class="text-left">
              {{ $t("note_text1") }}
            </li>
            <li class="text-left">
              {{ $t("note_text2") }}
            </li>
            <li class="text-left">
              {{ $t("note_text3") }}
            </li>
          </ul>
        </div>
        <div v-if="user_props === 'metered'">
          <div class="text-center my-5">
            <div class="button">
              <v-btn
                block
                large
                href="https://billing.stripe.com/p/login/00gbLi9Ku8CXf4cdQQ"
                target="_blank"
                >{{ $t("edit_membership") }}</v-btn
              >
            </div>
            <p class="stripe-text">{{ $t("note_stripe") }}</p>
          </div>
        </div>
      </div>
      <div v-if="user_props === 'limited'" class="main-text">
        <h2 class="mb-3 text-center">{{ $t("add_membership") }}</h2>
        <div class="text-center my-5">
          <div class="button">
            <v-btn block large @click="createSubscription">{{
              $t("subscribe")
            }}</v-btn>
          </div>
          <p class="stripe-text">{{ $t("note_stripe") }}</p>
        </div>
      </div>
      <div class="main-text">
        <div class="button">
          <amplify-sign-out></amplify-sign-out>
        </div>
      </div>
      <div class="text-center my-5">
        <h2 class="mb-3 text-center">{{ $t("analysis_history") }}</h2>
        <ul v-if="mtgHistory.length" class="list">
          <li v-for="(mtg, index) in mtgHistory" :key="mtg.mtg_id">
            {{ index + 1 }}． {{ tsToDateStr(mtg.ts) }}
            <a @click="download_csv(mtg.mtg_id)" class="mx-2 link-simple">
              [{{ $t("download") }}]
            </a>
          </li>
        </ul>
        <p v-else>no history</p>
      </div>
    </div>
    <div v-else class="main-box">
      <div class="button">
        <v-btn to="/signin" color="#ff9900"> {{ $t("login") }} </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { API, Storage } from "aws-amplify";
import axios from "axios";

export default {
  props: [
    "authState",
    "email",
    "userSub",
    "stripe_id",
    "user_props",
    "mtgHistory",
    "usageSummary",
    "usage",
  ],
  data() {
    return {
      isClicked: false,
    };
  },
  methods: {
    async createSubscription() {
      // ダブルクリック禁止
      if (this.isClicked) {
        return;
      }
      this.isClicked = true;

      if (!this.userSub) {
        console.error("failed to get user sub");
        return;
      }
      const myInit = {
        headers: {
          "content-type": "application/json",
        },
        queryStringParameters: {
          paymentId: "dummy_payment_id",
          userSub: this.userSub,
        },
      };
      const response = await API.post("subscription", "/create", myInit);
      // redirect to Stripe
      window.location.href = response["session_url"];
    },
    tsToDateStr(ts) {
      // ts ... timestamp
      // return ... YYYY/MM/DD hh:mm:ss
      return new Date(ts * 1000).toLocaleString();
    },
    loadMtgInfo: function(mtgId, callback) {
      const dataExpireSeconds = 60;
      console.log(this.mtgId);
      Storage.get("mtgs/" + mtgId + "/mtg_info.json", {
        level: "public",
        expires: dataExpireSeconds,
      })
        .then((url) => {
          this.axios.get(url).then((result) => {
            const mtgInfo = result.data;
            callback(mtgInfo);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getUrl: async function(key, mtgId, handler) {
      const dataExpireSeconds = 60 * 60; // 1時間有効なURLを取得
      Storage.get("mtgs/" + mtgId + "/" + key, {
        level: "public",
        expires: dataExpireSeconds,
      })
        .then((result) => {
          console.log(result);
          handler(result);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    download_csv: function(mtgId) {
      this.loadMtgInfo(mtgId, (mtgInfo) => {
        if (mtgInfo.stage == 8) {
          const downloadfilelist = mtgInfo["downloadfilelist"];
          for (let i = 0; i < downloadfilelist.length; i++) {
            this.getUrl(downloadfilelist[i], mtgId, (result) => {
              axios({
                url: result,
                method: "GET", // POSTでもok
                responseType: "blob", // これがないと文字化けする
              }).then((response) => {
                const url = URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", downloadfilelist[i]); //ここらへんは適当に設定する
                document.body.appendChild(link);
                link.click();
                link.revokeObjectURL();
              });
            });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
ul li {
  margin: 5px;
}
.list {
  list-style-type: none;
}
.main-box {
  justify-content: center;
  align-items: center;
  padding: 10px 20px 10px;
  background-color: #f5f5f5;
}
.membership-box {
  justify-content: center;
  align-items: center;
  max-width: 320px;
  margin: auto;
  padding: 5px 20px 10px;
}
.membership-text {
  color: grey;
  font-size: 13px;
  margin: 16px auto 2px;
}
.main-text {
  justify-content: center;
  align-items: center;
  max-width: 520px;
  margin: auto;
  padding: 5px 20px 10px;
}
.stripe-text {
  color: grey;
  font-size: 13px;
  margin: 8px auto 2px;
}
.button {
  width: 240px;
  margin: 10px auto;
}
.text-notice {
  color: grey;
  margin-bottom: 0px;
}
.link_simple {
  text-decoration: none;
}

h2 {
  color: #5e5e5e;
}
h3 {
  color: #5e5e5e;
}
</style>
